import React from "react"
import {Link} from "gatsby";

const Breadcrumb = (props) => (

    <li className={"breadcrumb-item spacing " + (props.breadcrumb.active ? 'active' : '')} aria-current="page">

        {!props.breadcrumb.active ?
            <Link to={props.breadcrumb.slug} className="text-black-800 spacing">
                {props.breadcrumb.text}
            </Link>
            :
            props.breadcrumb.text
        }
    </li>

)

export default Breadcrumb
