import React from "react"

import SEO from "../components/common/seo"
import Breadcrumbs from "../components/common/breadcrumbs";
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../utils/global-functions";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import {Link} from "@reach/router";
import rehypeRaw from "rehype-raw";

const PrivacityPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiLegalPrivacidad.edges[0].node;
    const breadcrumbs = [
        { text: 'Legal', active: false },
        { text: page.title.replace('Tecalis ', ''), active: true },
    ];
    const privacyElements = (aClassName = '') => {
        return {
            p: ({node, ...props}) => <p className='text-black-800 mb-2 spacing font-size-sm' {...props} />,
            h2: ({node, ...props}) => <h5 className='font-weight-bold spacing font-weight-bold' {...props} />,
            h3: ({node, ...props}) => <h3 className='font-weight-bold spacing text-tecalis' {...props} />,
            h5: ({node, ...props}) => <h5 className='font-weight-bold mt-6 spacing font-weight-bold' {...props} />,
            a: ({node, ...props}) => {
                if (props.href.startsWith('http')) {
                    return <OutboundLink href={props.href} target="_blank" rel="noopener noreferrer" className={aClassName} {...props} />
                }

                return <Link to={props.href} className={aClassName} {...props} />
            },
        }
    }

    return (
        <LayoutSecondary pageContext={pageContext}>
            <SEO lang={lang} title={page.seo.title} description={page.seo.meta_description}/>

            <Breadcrumbs breadcrumbs={breadcrumbs}/>

            {/** LEGAL
             ================================================== */}
            <section className="bg-grey pt-8 pt-md-9">
                <div className="container">

                    {/** Heading */}
                    <h1 className="display-4 font-weight-bold spacing">
                        {page.title}
                        <br/><br/>
                    </h1>

                    <div className="row">
                        <div className="col-12 col-md-12">

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.responsible_title}
                            </h3>
                            <br/>

                            <ReactMarkdown children={page.responsible_description} rehypePlugins={[rehypeRaw]} components={customElements('text-black-800 mb-6 spacing font-size-sm')} />

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    {page.responsible_items.map((item, i) => {
                                        return <tr key={i}>
                                            <td className="spacing font-size-sm font-weight-bold">{item.title}</td>
                                            <td className="spacing font-size-sm">{item.description}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                            <br/>
                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.delegate_title}
                            </h3>
                            <br/>

                            <ReactMarkdown children={page.delegate_description} rehypePlugins={[rehypeRaw]} components={customElements('text-black-800 mb-6 spacing font-size-sm')} />

                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    {page.delegate_items.map((item, i) => {
                                        return <tr key={i}>
                                            <td className="spacing font-size-sm font-weight-bold">{item.title}</td>
                                            <td className="spacing font-size-sm">{item.description}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.general_info_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.general_info_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.supply_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.supply_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.cookies_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.cookies_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tr>
                                        <th>Cookies</th>
                                        <th>Finalidad</th>
                                        <th>Titular</th>
                                        <th>Duración</th>
                                    </tr>

                                    {page.cookies_items.map((item, i) => {
                                        return <tr key={i}>
                                            <td className="spacing font-size-sm">{item.name}</td>
                                            <td className="spacing font-size-sm">{item.purpose}</td>
                                            <td className="spacing font-size-sm">{item.titular}</td>
                                            <td className="spacing font-size-sm">{item.duration}</td>
                                        </tr>
                                    })}
                                </table>
                            </div>

                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.newsletter_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.newsletter_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.contact_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.contact_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.form_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.form_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.appearances_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.appearances_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.presences_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.presences_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.hosting_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.hosting_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />
                            <br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.geotargeting_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.geotargeting_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} /><br/>

                            <h3 className="font-weight-bold spacing text-tecalis">
                                {page.plugins_title}
                            </h3>
                            <br/>
                            <ReactMarkdown children={page.plugins_description} rehypePlugins={[rehypeRaw]} components={privacyElements()} />

                            <br/><br/><br/>

                        </div>

                    </div>
                </div>
                {/** / .row */}
                {/** / .container */}
            </section>

        </LayoutSecondary>
    )
};

export default PrivacityPage

export const privacityPageQuery = graphql
    `query($lang: String)
    {
        allStrapiLegalPrivacidad (
            filter: { locale: { eq: $lang } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                    }
                    title
                    responsible_title
                    responsible_description
                    responsible_items {
                        order
                        title
                        description
                    } 
                    delegate_title
                    delegate_description
                    delegate_items {
                        order
                        title
                        description
                    } 
                    general_info_title
                    general_info_description
                    supply_title
                    supply_description
                    cookies_title
                    cookies_description
                    cookies_items {
                        name
                        purpose
                        titular
                        duration
                    }
                    newsletter_title
                    newsletter_description
                    contact_title
                    contact_description
                    form_title
                    form_description
                    appearances_title
                    appearances_description
                    presences_title
                    presences_description
                    hosting_title
                    hosting_description
                    geotargeting_title
                    geotargeting_description
                    plugins_title
                    plugins_description
                }
            }
        }
    }`;
