import React from "react"
import Breadcrumb from "./breadcrumb";

const Breadcrumbs = (props) => (

    <nav className="bg-gray-100 breadcrumb-fixed">
        <div className="container">
            <div className="row">
                <div className="col-12">

                    {/** Breadcrumb */}
                    <ol className="breadcrumb breadcrumb-scroll">
                        {props.breadcrumbs.map((breadcrumb, i) => (
                            <Breadcrumb breadcrumb={breadcrumb} key={i}/>
                        ))}
                    </ol>

                </div>
            </div>
            {/** / .row */}
        </div>
        {/** / .container */}
    </nav>

)

export default Breadcrumbs
