import React from "react"
import PropTypes from "prop-types"
import Nav from "./nav";

const HeaderSecondary = ({pageContext}) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white border-bottom">
            <Nav pageContext={pageContext}/>
        </nav>
    )
};

HeaderSecondary.propTypes = {
    siteTitle: PropTypes.string,
};

HeaderSecondary.defaultProps = {
    siteTitle: ``,
};

export default HeaderSecondary
