/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"
import {Helmet} from "react-helmet"
import SEO from "./seo"
import Footer from "./footer"
import CookieLand from "./cookieland";
import HeaderSecondary from "./header-secondary";
import Feder from "./feder";
import ModalDexga from "./modal-dexga";
import favicon from "../../img/favicon.ico";

const LayoutSecondary = ({children, pageContext, cookies = null}) => {

    const data = useStaticQuery(graphql`
    query SiteTitleSecondaryQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <>
            <Helmet
                defer={false}
                title="Tecalis"
                htmlAttributes={{"lang": pageContext.locale.path.default}}>
                <link rel="icon" href={favicon}/>
                {/*<script src="https://kit.fontawesome.com/dd5f5ce6f3.js" crossOrigin="anonymous"/>*/}
                <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer/>
                <link rel="stylesheet" href="https://use.typekit.net/acr2trl.css"/>
            </Helmet>

            <SEO lang={pageContext.locale.path.default}/>

            <HeaderSecondary siteTitle={data.site.siteMetadata.title} pageContext={pageContext}/>
            {children}
            <ModalDexga/>
            {cookies && <CookieLand data={cookies} pageContext={pageContext}/>}
            <Feder/>
            <Footer pageContext={pageContext}/>
        </>
    )
};

LayoutSecondary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LayoutSecondary
